
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faCommentDollar as farCommentDollar,
    } from '@fortawesome/pro-regular-svg-icons/faCommentDollar';

    export default {
        name: 'JobCardSimilarPayDetailed',

        components: {
            FontAwesomeIcon,
        },

        props: {
            pay: {
                type: String,
                default: '',
            },

            payDescription: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                icons: {
                    farCommentDollar,
                },
            };
        },
    };
