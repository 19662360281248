
    export default {
        name: 'ScoreUnlockIcon',

        props: {
            size: {
                type: String,
                default: '',
            },

            showLabel: {
                type: Boolean,
                default: false,
            },

            showButton: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {};
        },

        computed: {
            classList() {
                return [
                    'score-unlock',
                    this.getSizeClass,
                    this.getLabelClass,
                ];
            },

            getSizeClass() {
                return this.size ? `is-${this.size}` : '';
            },

            getLabelClass() {
                return this.showLabel ? 'with-label' : '';
            },
        },

        methods: {},
    };
