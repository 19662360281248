export default {
    calculateScoreFrontEnd(inputJobData, inputSeekerData, matrixData) {
        const jobData = JSON.parse(JSON.stringify(inputJobData));
        const seekerData = JSON.parse(JSON.stringify(inputSeekerData));
        let sum = 0;
        let confidenceScore = 0;
        let score = 0;
        const scoreDetails = [];

        if (!jobData || !seekerData) {
            return null;
        }
        // Job title
        if (!jobData.normalised_job_title_id && jobData.base_normalised_job_title_id && jobData.base_normalised_job_title_id > 0) {
            jobData.normalised_job_title_id = [jobData.base_normalised_job_title_id];
        }
        // occupations
        if (jobData.occupations_list || (jobData.category_id && jobData.category_id > 0) || jobData.normalised_job_title_id) {
            sum += matrixData.occupation;
            confidenceScore += matrixData.occupation;
            const scoreDetailsoccupation = {
                title: 'Occupation',
                score: -1,
            };
            let occupationScore = 0;
            if (seekerData.occupations) {
                occupationScore = 0;
                if (jobData.occupations_list) {
                    // eslint-disable-next-line
                    occupationLoops: for (const jobOccupation of jobData.occupations_list) {
                        // eslint-disable-next-line
                        for (const userOccupation of seekerData.occupations) {
                            // eslint-disable-next-line
                            if (jobOccupation.occupation_id == userOccupation.occupation_id) {
                                occupationScore = Math.max(Math.floor(100 * jobOccupation.weight * userOccupation.weight), occupationScore);
                            }
                        }
                    }
                }
                if (!occupationScore) {
                    // eslint-disable-next-line
                    if (jobData.category_id == seekerData.category_id) {
                        occupationScore = 20;
                    }
                }
            }
            let baseNormalisedJobTitlescore = 0;
            if (jobData.normalised_job_title_id || (jobData.base_normalised_job_title_id && jobData.base_normalised_job_title_id > 0)) {
                let jobTitlescore = 0;
                if (seekerData.job_titles) {
                    // eslint-disable-next-line
                    jtLoop: for (const userJobTitle of seekerData.job_titles) {
                        // eslint-disable-next-line
                        for (const jobJobTitle of jobData.normalised_job_title_id) {
                            // eslint-disable-next-line
                            if (jobData.base_normalised_job_title_id  == userJobTitle.job_title_id && jobData.category_id == seekerData.category_id) {
                                baseNormalisedJobTitlescore = Math.floor(100 * userJobTitle.weight);
                            }
                            // eslint-disable-next-line
                            if (jobJobTitle == userJobTitle.job_title_id) {
                                // eslint-disable-next-line
                                const jobFactor = jobJobTitle == jobData.base_normalised_job_title_id ? 1.00 : 0.70;
                                jobTitlescore = Math.floor(100 * jobFactor * userJobTitle.weight);
                                // eslint-disable-next-line
                                break jtLoop;
                            }
                        }
                    }
                }
                baseNormalisedJobTitlescore = Math.max(Math.floor(Math.floor(0.8 * occupationScore) + (jobTitlescore * 0.2)), baseNormalisedJobTitlescore);
            }
            scoreDetailsoccupation.score = Math.max(occupationScore, baseNormalisedJobTitlescore);
            score += Math.floor(matrixData.occupation * scoreDetailsoccupation.score / 100);
            scoreDetails.push(scoreDetailsoccupation);
        }

        // salary
        if (jobData.pay_max) {
            const jobMaxSalary = jobData.pay_max;
            sum += matrixData.salary;
            confidenceScore += matrixData.salary;
            const scoreDetailsSalary = {
                title: 'Salary',
                score: -1,
            };
            if (seekerData.min_pay_band) {
                const userDesiredSalary = Math.min(seekerData.min_pay_band, 300000);
                scoreDetailsSalary.score = 0;
                if (jobMaxSalary >= userDesiredSalary) {
                    score += matrixData.salary;
                    scoreDetailsSalary.score = 100;
                } else if (jobMaxSalary >= (userDesiredSalary * 0.9)) {
                    score += matrixData.salary * 0.9;
                    scoreDetailsSalary.score = 90;
                } else if (jobMaxSalary >= (userDesiredSalary * 0.8)) {
                    score += matrixData.salary * 0.3;
                    scoreDetailsSalary.score = 30;
                }
            }
            scoreDetails.push(scoreDetailsSalary);
        }

        // location
        sum += matrixData.location;
        confidenceScore += matrixData.location;
        const scoreDetailsLocation = {
            title: 'Desired Location',
            score: -1,
        };
        if (seekerData.work_locations) {
            scoreDetailsLocation.score = 0;
            const jobLocMatrix = [];

            if (jobData.location_level_1) {
                jobLocMatrix.push(jobData.location_level_1.toString());
            }
            if (jobData.location_level_2) {
                jobLocMatrix.push(jobData.location_level_2.toString());
            }
            if (jobData.location_level_3) {
                jobLocMatrix.push(jobData.location_level_3.toString());
            }
            if (this.in_array_in_array(seekerData.work_locations, jobLocMatrix)) {
                score += matrixData.location;
                scoreDetailsLocation.score = 100;
            } else if (seekerData.work_locations_area && this.array_intersect(seekerData.work_locations_area, jobLocMatrix).length) {
                score += matrixData.location * 0.9;
                scoreDetailsLocation.score = 90;
            } else if (seekerData.work_locations_region && this.array_intersect(seekerData.work_locations_region, jobLocMatrix).length) {
                score += matrixData.location * 0.7;
                scoreDetailsLocation.score = 70;
            }
            scoreDetails.push(scoreDetailsLocation);
        }

        // home location home_location_id
        sum += matrixData.home_location;
        confidenceScore += matrixData.home_location;
        const scoreDetailsHomeLocation = {
            title: 'Home Location',
            score: -1,
        };
        if (seekerData.home_location_id) {
            scoreDetailsHomeLocation.score = 0;
            seekerData.home_location = [seekerData.home_location_id.toString()];
            const jobLocMatrix = [];

            if (jobData.location_level_1) {
                jobLocMatrix.push(jobData.location_level_1.toString());
            }
            if (jobData.location_level_2) {
                jobLocMatrix.push(jobData.location_level_2.toString());
            }
            if (jobData.location_level_3) {
                jobLocMatrix.push(jobData.location_level_3.toString());
            }
            if (this.in_array_in_array(jobLocMatrix, seekerData.home_location)) {
                score += matrixData.home_location;
                scoreDetailsHomeLocation.score = 100;
            } else if (seekerData.home_location_region_id && this.in_array(seekerData.home_location_region_id.toString(), jobLocMatrix)) {
                score += matrixData.home_location;
                scoreDetailsHomeLocation.score = 100;
            }
            scoreDetails.push(scoreDetailsHomeLocation);
        }

        // skills
        if (jobData.skills_scoring) {
            sum += matrixData.skills;
            confidenceScore += matrixData.skills;
            const scoreDetailsSkills = {
                title: 'Skills',
                score: -1,
            };
            if (seekerData.skills) {
                scoreDetailsSkills.score = 0;
                if (jobData.skills_scoring.length > 0) {
                    const skillIntersec = (this.array_intersect(seekerData.skills, jobData.skills_scoring)).length;
                    score += Math.floor(matrixData.skills * (skillIntersec / jobData.skills_scoring.length));
                    scoreDetailsSkills.score = Math.floor((skillIntersec / (jobData.skills_scoring).length) * 100);
                }
            }
            scoreDetails.push(scoreDetailsSkills);
        }

        // industry
        if (jobData.industry) {
            sum += matrixData.industry;
            const scoreDetailsIndustry = {
                title: 'Industry',
                score: 0,
            };
            if (!seekerData.industries || this.in_array(jobData.industry, seekerData.industries)) {
                score += matrixData.industry;
                scoreDetailsIndustry.score = 100;
            }
            scoreDetails.push(scoreDetailsIndustry);
        }

        // career_level
        if (jobData.career_level) {
            sum += matrixData.career_level;
            confidenceScore += matrixData.career_level;
            const scoreDetailsCareerLevel = {
                title: 'Career level',
                score: -1,
            };
            if (seekerData.career_level) {
                scoreDetailsCareerLevel.score = 0;
                if (this.in_array(seekerData.career_level.toString(), jobData.career_level)) {
                    score += matrixData.career_level;
                    scoreDetailsCareerLevel.score = 100;
                }
            }
            scoreDetails.push(scoreDetailsCareerLevel);
        }

        // certifications
        if (jobData.certifications) {
            sum += matrixData.certifications;
            confidenceScore += matrixData.certifications;
            const scoreDetailsCertifications = {
                title: 'Certifications & licenses',
                score: -1,
            };
            if (seekerData.certifications) {
                scoreDetailsCertifications.score = 0;
                const certificationsIntersec = Math.min((this.array_intersect(seekerData.certifications, jobData.certifications).length), 3);
                const count = Math.min(jobData.certifications.length, 3);
                score += Math.round(matrixData.certifications * (certificationsIntersec / count));
                scoreDetailsCertifications.score = Math.round((certificationsIntersec / count) * 100);
            }
            scoreDetails.push(scoreDetailsCertifications);
        }

        // education level
        if (jobData.education_level) {
            sum += matrixData.education;
            confidenceScore += matrixData.education;
            const scoreDetailsEducation = {
                title: 'Education',
                score: -1,
            };
            if (seekerData.education_level) {
                scoreDetailsEducation.score = 0;
                if (this.in_array_in_array(seekerData.education_level, jobData.education_level)) {
                    score += matrixData.education;
                    scoreDetailsEducation.score = 100;
                }
            }
            scoreDetails.push(scoreDetailsEducation);
        }

        // Languages
        if (jobData.languages) {
            sum += matrixData.languages;
            confidenceScore += matrixData.languages;
            const scoreDetailsLanguages = {
                title: 'Languages',
                score: -1,
            };
            if (seekerData.languages && jobData.languages) {
                scoreDetailsLanguages.score = 0;
                const languagesIntersec = Math.min((this.array_intersect(seekerData.languages, jobData.languages)).length, 3);
                const count = Math.min((jobData.languages).length, 3);
                score += Math.round(matrixData.languages * (languagesIntersec / count));
                scoreDetailsLanguages.score = Math.round((languagesIntersec / count) * 100);
            }
            scoreDetails.push(scoreDetailsLanguages);
        }

        // jobtype
        if (jobData.job_type_id && jobData.job_type_id > 0) {
            sum += matrixData.job_type;
            confidenceScore += matrixData.job_type;
            const scoreDetailsjobType = {
                title: 'Job type',
                score: 0,
            };
            // full-time if job type is null
            if (!seekerData.job_types) {
                seekerData.job_types = ['1'];
            }
            if (this.in_array(jobData.job_type_id, seekerData.job_types)) {
                score += matrixData.job_type;
                scoreDetailsjobType.score = 100;
            }
            scoreDetails.push(scoreDetailsjobType);
        }

        // contract_type
        if (jobData.contract_type_id && jobData.contract_type_id > 0) {
            sum += matrixData.contract_type;
            const scoreDetailscontractType = {
                title: 'Contract type',
                score: -1,
            };
            if (seekerData.contract_types && jobData.contract_type_id) {
                scoreDetailscontractType.score = 0;
                if (this.in_array(jobData.contract_type_id, seekerData.contract_types)) {
                    score += matrixData.contract_type;
                    scoreDetailscontractType.score = 100;
                }
            }
            scoreDetails.push(scoreDetailscontractType);
        }

        // sector
        if (jobData.sector) {
            sum += matrixData.sector;
            const scoreDetailsSector = {
                title: 'Sector',
                score: -1,
            };
            if (seekerData.sectors) {
                scoreDetailsSector.score = 0;
                if (this.in_array(jobData.sector, seekerData.sectors)) {
                    score += matrixData.sector;
                    scoreDetailsSector.score = 100;
                }
            }
            scoreDetails.push(scoreDetailsSector);
        }

        // job_mode
        if (jobData.job_mode_id) {
            sum += matrixData.job_mode;
            const scoreDetailsjobMode = {
                title: 'Job mode',
                score: -1,
            };
            scoreDetailsjobMode.score = 0;
            if (!seekerData.job_modes || this.in_array(jobData.job_mode_id, seekerData.job_modes)) {
                score += matrixData.job_mode;
                scoreDetailsjobMode.score = 100;
            }
            scoreDetails.push(scoreDetailsjobMode);
        }

        // work_authorisation
        if (!jobData.work_authorisations) {
            jobData.work_authorisations = ['66', '67'];
        }
        sum += matrixData.work_authorisation;
        confidenceScore += matrixData.work_authorisation;
        const scoreDetailsWorkAuthorisation = {
            title: 'Work authorisation',
            score: -1,
        };
        if (seekerData.work_authorisation) {
            scoreDetailsWorkAuthorisation.score = 0;

            if (seekerData.work_authorisation === 67) {
                seekerData.work_authorisation = 66;
            }
            if (this.in_array(seekerData.work_authorisation.toString(), jobData.work_authorisations)) {
                score += matrixData.work_authorisation;
                scoreDetailsWorkAuthorisation.score = 100;
            }
            scoreDetails.push(scoreDetailsWorkAuthorisation);
        }
        // company size
        if (seekerData.company_sizes && jobData.company_size_id) {
            sum += matrixData.company_size;
            const scoreDetailsCompanySize = {
                title: 'Company size',
                score: 0,
            };
            if (this.in_array(jobData.company_size_id, seekerData.company_sizes)) {
                score += matrixData.company_size;
                scoreDetailsCompanySize.score = 100;
            }
            scoreDetails.push(scoreDetailsCompanySize);
        }
        const percentage = (sum > 0) ? (Math.floor((score / sum) * 100)) : 0;
        const confidenceSum = matrixData.job_title +
            matrixData.occupation +
            matrixData.salary +
            matrixData.location +
            matrixData.home_location +
            matrixData.skills +
            matrixData.career_level +
            matrixData.certifications +
            matrixData.education +
            matrixData.languages +
            matrixData.job_type +
            matrixData.work_authorisation;

        const percentageConfidence = (confidenceScore > 0) ? (Math.floor((confidenceScore / confidenceSum) * 100)) : 0;
        let confidence = -1;
        if (percentageConfidence <= matrixData.confidence_low) {
            confidence = 0;
        } else if (percentageConfidence >= matrixData.confidence_high) {
            confidence = 1;
        }

        return {
            percentage, sum, score, scoreDetails, confidence, confidenceSum, confidenceScore,
        };
    },

    in_array(value, array) {
        if (!array || !value) {
            return false;
        }
        return array.includes(value.toString());
    },

    in_array_in_array(arr1, arr2) {
        // eslint-disable-next-line
        for (let i = 0; i < arr1.length; ++i) {
            if (arr2.includes(arr1[i])) {
                return true;
            }
        }
        return false;
    },

    array_intersect(arr1, arr2) {
        const c = [];
        // eslint-disable-next-line
        for (let i = 0; i < arr1.length; ++i) {
            if (arr1[i] && (arr2.includes(arr1[i].toString()) || arr2.includes(parseInt(arr1[i], 10)))) {
                c.push(arr1[i]);
            }
        }
        return c;
    },
};
