
    import { mapState } from 'vuex';
    import {
        faCheckCircle as farCheckCircle,
    } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';

    export default {
        name: 'JobStatus',

        props: {
            job: {
                type: Object,
                required: true,
            },

            variant: {
                type: String,
                default: 'solid',
            },

            shadow: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                icons: {
                    farCheckCircle,
                },
            };
        },

        computed: {
            ...mapState({
                $_meJobEvents: state => state.me.jobEvents,
            }),

            classList() {
                return [
                    'job__status-label',
                    this.jobStatus,
                    this.getVariantClass,
                    this.getShadowClass,
                ];
            },

            getShadowClass() {
                return this.shadow ? 'shadow-sm' : '';
            },

            getVariantClass() {
                const variant = {
                    solid: 'is-solid',
                    outline: 'is-outline',
                };

                return variant[this.variant];
            },

            jobStatus() {
                let jobStatus = '';
                const job = this.$_meJobEvents.find(o => o.job_uuid === this.job.uuid);

                if (job) {
                    if (job.is_viewed) {
                        jobStatus = 'viewed';
                    }
                    if (job.is_applied) {
                        jobStatus = 'applied';
                        if (this.job.apply_type_id === 2 || this.job.apply_type_id === 3 || this.job.apply_type_id === 8) {
                            jobStatus = 'clicked';
                        }
                    }
                    if (job.is_incomplete_apply) {
                        jobStatus = 'incomplete';
                    }
                }
                return jobStatus;
            },
        },
    };
