
    import { mapState, mapMutations, mapActions } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    import {
        faChevronRight as fasChevronRight,
    } from '@fortawesome/pro-solid-svg-icons/faChevronRight';

    import {
        faEllipsisVertical as farEllipsisVertical,
    } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical';

    import {
        faArrowAltUp as fasArrowAltUp,
    } from '@fortawesome/pro-solid-svg-icons/faArrowAltUp';

    import {
        faEyeSlash as farEyeSlash,
    } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';

    import {
        faCommentDollar as farCommentDollar,
    } from '@fortawesome/pro-regular-svg-icons/faCommentDollar';

    import {
        faArrowUpRightAndArrowDownLeftFromCenter as fasArrowUpRightAndArrowDownLeftFromCenter,
    } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightAndArrowDownLeftFromCenter';

    import JobSave from '@/components/Job/JobSave';
    import JobCardLocations from '@/components/Job/JobCard/JobCardLocations';
    import JobCardAttributes from '@/components/Job/JobCard/JobCardAttributes';
    import JobCardCategoryInfo from '@/components/Job/JobCard/JobCardCategoryInfo';
    import JobStatus from '@/components/Job/JobStatus';
    import JobDate from '@/components/Job/JobDate';
    import ScoreButton from '@/components/Scoring/ScoreButton';
    import JobCardBrand from '@/components/Job/JobCard/JobCardBrand';
    import scoreHelper from '@/utils/scoreHelper';
    import { slugify } from '@/utils/helpers';
    import JobPay from '@/utils/JobPay.class';
    import { jobData } from '@/utils/jobData';
    import JobCardSimilarPayDetailed from '@/components/Job/JobCard/JobCardSimilarPayDetailed';
    import datalayer from '@/utils/datalayer';

    export default {
        name: 'JobCardDetailed',

        components: {
            JobSave,
            JobCardLocations,
            JobCardAttributes,
            JobCardCategoryInfo,
            JobDate,
            ScoreButton,
            CompanyCarouselLightbox: () => import(/* webpackChunkName: "CompanyCarouselLightbox" */'@/components/Company/CompanyCarouselLightbox'),
            JobStatus,
            JobCardSimilarPayDetailed,
            JobCardBrand,
            FontAwesomeIcon,
        },

        props: {
            job: {
                type: Object,
                default: () => { return {}; },
            },

            noHover: {
                type: Boolean,
                default: false,
            },

            jobIndex: {
                type: Number,
                default: 0,
            },

            rowLabel: {
                type: String,
                default: '',
            },

            rowType: {
                type: String,
                default: '',
            },

            rowPosition: {
                type: Number,
                default: 0,
            },
        },

        data() {
            return {
                jobMutable: {},
                icons: {
                    fasChevronRight,
                    fasArrowAltUp,
                    farEllipsisVertical,
                    farEyeSlash,
                    fasArrowUpRightAndArrowDownLeftFromCenter,
                    farCommentDollar,
                },
                showLazyComponents: false,
                showPromptModal: false,
                onBoardingPrompt: {
                    component_name: 'OnBoardingPrompt',
                    layout: 'lg',
                    code: 'ON_BOARDING_PROMPT',
                    page_referral_element: 'score_button',
                },
            };
        },

        computed: {
            ...mapState({
                $_me: state => state.me.user,
                $_scoringData: state => state.me.scoringData,
                $_scoringMatrixData: state => state.me.scoringMatrixData,
                $_appInfo: state => state.appInfo,
            }),

            classList() {
                return [
                    this.getPrivateListingClass,
                    this.getNoHoverClass,
                    this.getIsPayEstimatedClass,
                    this.getIsDetailedView,
                ];
            },

            getPrivateListingClass() {
                return this.isPrivateListing ? 'is-private' : '';
            },

            getNoHoverClass() {
                return this.noHover ? 'no-hover' : '';
            },

            getIsPayEstimatedClass() {
                return this.isPayEstimated ? 'is-pay-estimated' : '';
            },

            getIsDetailedView() {
                return this.showDetails ? 'is-show-detailed' : '';
            },

            showPay() {
                if (!this.jobMutable.pay_type) {
                    return false;
                }

                if (this.jobMutable.pay_min <= 0 && this.jobMutable.pay_max <= 0) {
                    return false;
                }

                return true;
            },

            companyLogoUrl() {
                return this.jobMutable.company_logo_url || '';
            },

            companySquareLogoUrl() {
                return this.jobMutable.company_square_logo_url || '';
            },

            payType() {
                return this.jobMutable.pay_type || '';
            },

            pay() {
                return new JobPay(
                    this.jobMutable.pay_min,
                    this.jobMutable.pay_max,
                    this.jobMutable.pay_type,
                    false,
                    this.isPayEstimated,
                    this.jobMutable.job_type_label,
                    this.jobMutable.contract_type_label,
                );
            },

            payMinMaxLabel() {
                return this.pay ? this.pay.payMinMaxLabel : '';
            },

            payMinMaxShortLabel() {
                return this.pay ? this.pay.payMinMaxShortLabel : '';
            },

            cta() {
                return {
                    name: 'jobview-slug-id',
                    params: {
                        slug: slugify(this.jobMutable.job_title),
                        id: this.jobMutable.uuid,
                    },
                };
            },

            ctaLink() {
                if (this.job.apply_type_id === 8) {
                    return `${this.$siteUrl}job-redirect/${this.job.uuid}`;
                }
                if (this.rowLabel) {
                    return `${this.$router.resolve(this.cta).href}?page_referral_element=${this.rowLabel}`;
                }
                return this.$router.resolve(this.cta).href;
            },

            scoring() {
                return scoreHelper.calculateScoreFrontEnd(this.jobMutable, this.$_scoringData, this.$_scoringMatrixData);
            },

            scoringOccupation() {
                return this.scoring.scoreDetails.find(o => o.title === 'Occupation');
            },

            scoringSkills() {
                return this.scoring.scoreDetails.find(o => o.title === 'Skills');
            },

            scoringSkillsVariant() {
                if (!this.scoringSkills) {
                    return null;
                }

                let output;
                const score = this.scoringSkills.score;

                if (score <= 33) {
                    output = 'danger';
                } else if (score <= 66) {
                    output = 'warning';
                } else {
                    output = 'success';
                }

                return output;
            },

            brandMedias() {
                return this.jobMutable.brand_details?.medias && this.jobMutable.brand_details.medias.length ? this.jobMutable.brand_details.medias : [];
            },

            isPrivateListing() {
                return this.jobMutable.brand_is_hidden;
            },

            isPayEstimated() {
                return !!this.jobMutable.pay_is_estimated;
            },

            payDescription() {
                return this.jobMutable.pay_description || '';
            },

            isPayAboveAvg() {
                return !!this.jobMutable.pay_is_above_avg;
            },

            companyNameLabel() {
                return this.isPrivateListing ? 'Private Listing' : this.jobMutable.company_name;
            },

            primaryCompanyNameLabel() {
                return this.isPrivateListing ? '' : this.job.primary_brand_name;
            },

            displayPrimaryBrandCrumb() {
                return this.jobMutable.display_primary_brand_crumb;
            },

            companyLink() {
                if (this.jobMutable.brand_slug && !this.jobMutable.brand_is_hidden) {
                    return `/jobs/br_${this.jobMutable.brand_slug}`;
                }
                return '/jobs';
            },

            primaryCompanyLink() {
                if (this.jobMutable.primary_brand_slug) {
                    return `/jobs/br_${this.jobMutable.primary_brand_slug}`;
                }
                return '';
            },

            jobData() {
                return {
                    ...jobData(this.jobMutable),
                    seeker_match_score: this.scoring,
                    seeker_data: this.$_scoringData,
                };
            },

            attributes() {
                return [
                    this.jobMutable.job_type_label,
                    this.jobMutable.contract_type_label,
                    this.jobMutable.job_mode_label,
                ];
            },

            badgeImgUrl() {
                return require('@/assets/img/EEO.svg');
            },
        },

        created() {
            this.jobMutable = JSON.parse(JSON.stringify(this.job));
        },

        mounted() {
            window.addEventListener('scroll', () => {
                if (!this.showLazyComponents) {
                    this.showLazyComponents = true;
                }
            });
        },

        methods: {
            ...mapMutations('jobModal', {
                $_STORE_JOB_UUID: 'STORE_JOB_UUID',
                $_STORE_VERTICAL_SCROLL_POSITION: 'STORE_VERTICAL_SCROLL_POSITION',
                $_STORE_SECTION_TITLE: 'STORE_SECTION_TITLE',
            }),

            ...mapMutations('me', {
                $_PUT_JOB_EVENT: 'PUT_JOB_EVENT',
            }),

            ...mapActions('eventTracking', {
                $_pushEvent: 'pushEvent',
                $_pushSeekerJobEvent: 'pushSeekerJobEvent',
            }),

            ...mapActions('me', {
                $_toggleJobVisibility: 'toggleJobVisibility',
            }),

            openPromptModal() {
                this.showPromptModal = true;
            },

            closePromptModal() {
                this.showPromptModal = false;
            },

            toggleJobVisibility(jobUuid) {
                this.$_toggleJobVisibility(jobUuid).then(() => {
                    this.$_PUT_JOB_EVENT({ job: jobUuid, status: 'is_hidden' });
                });
            },

            getCategoryLink() {
                const routePath = this.$route.path;
                let categoryLink = `/jobs-in-${this.jobMutable.category_slug}`;
                if (this.jobMutable.location_level_3_slug && !routePath.includes('/in-')) {
                    const locationLink = `in-${this.jobMutable.location_level_3_slug}`;
                    categoryLink = `${categoryLink}/${locationLink}`;
                }
                return categoryLink;
            },

            getOccupationLink() {
                const routePath = this.$route.path;
                let occupationLink = '/jobs';
                if (this.jobMutable.location_level_3_slug && !routePath.includes('/in-')) {
                    const locationLink = `in-${this.jobMutable.location_level_3_slug}`;
                    occupationLink = `${occupationLink}/${locationLink}`;
                }
                occupationLink = `${occupationLink}/occ_${this.jobMutable.occupation_slug}`;
                return occupationLink;
            },

            getEEOTags() {
                if (this.jobMutable.brand_is_hidden) {
                    return [];
                }
                return this.jobMutable.brand_details?.equal_opportunity_tags;
            },

            goToJob(modal = false, rowLabel = '', target = '_blank', cta = '') {
                this.jobClickEvent(cta);
                if (this.rowType === 'SIMILAR_JOBS') {
                    modal = false;
                }
                if (this.job.apply_type_id === 8) {
                    modal = false;
                    target = '_blank';
                }
                modal ? this.openJobModal(rowLabel) : this.redirectJob(target);
            },

            openJobModal(rowLabel = '') {
                this.$_STORE_JOB_UUID(this.jobMutable.uuid);
                this.$_STORE_VERTICAL_SCROLL_POSITION(window.scrollY);
                this.$_STORE_SECTION_TITLE(rowLabel);
            },

            redirectJob(target = '_blank') {
                window.open(this.ctaLink, target);
            },

            visibilityChanged(isVisible) {
                if (isVisible) {
                    const data = {
                        event: 'job_impression',
                        site_position: this.rowType,
                        page_referral_element: this.rowLabel,
                        site_position_label: this.rowLabel,
                        job_position: this.jobIndex + 1,
                        row_position: this.rowPosition + 1,
                        ...this.jobData,
                    };
                    this.$_pushEvent(data);
                    datalayer(data);
                }
            },

            jobClickEvent(cta) {
                const data = {
                    event: 'job_click',
                    site_position: this.rowType,
                    page_referral_element: this.rowLabel,
                    site_position_label: this.rowLabel,
                    cta,
                    job_position: this.jobIndex + 1,
                    row_position: this.rowPosition + 1,
                    ...this.jobData,
                };
                this.$_pushEvent(data);
                this.$_pushSeekerJobEvent(data);
                datalayer(data);
            },
        },
    };
