
    import { mapState } from 'vuex';
    import JobCardDetailed from '@/components/Job/JobCard/JobCardDetailed';

    export default {
        name: 'SearchJobList',

        components: {
            JobCardDetailed,
            JobCardStacked: () => import(/* webpackChunkName: "JobCardStacked" */'@/components/Job/JobCard/JobCardStacked'),
            JobCardSimple: () => import(/* webpackChunkName: "JobCardSimple" */'@/components/Job/JobCard/JobCardSimple'),
            ResumeWriteBanner: () => import(/* webpackChunkName: "ResumeWriteBanner" */'@/components/ResumeWriting/ResumeWriteBanner'),
            GetJobMatchesPromptSm: () => import(/* webpackChunkName: "GetJobMatchesPromptSm" */'@/components/Prompts/Components/GetJobMatchesPromptSm'),
            GetJobMatchesPromptMd: () => import(/* webpackChunkName: "GetJobMatchesPromptMd" */'@/components/Prompts/Components/GetJobMatchesPromptMd'),
            GetJobMatchesPromptLg: () => import(/* webpackChunkName: "GetJobMatchesPromptLg" */'@/components/Prompts/Components/GetJobMatchesPromptLg'),
        },

        props: {
            jobs: {
                type: Array,
                default: () => [],
            },

            startIndex: {
                type: Number,
                default: 0,
            },
        },

        data() {
            return {
                isJobMatchPromptVisible: false,
                showAllJobs: false,
            };
        },

        computed: {
            ...mapState({
                $_me: state => state.me.user,
                $_meJobEvents: state => state.me.jobEvents,
            }),
        },

        mounted() {
            window.addEventListener('scroll', () => {
                if (!this.showAllJobs) {
                    this.showAllJobs = true;
                }
            });
        },

        methods: {
            onJobMatchPromptVisible(isVisible) {
                if (isVisible) {
                    this.isJobMatchPromptVisible = true;
                }
            },

            canShowJobCard(index) {
                if (index === 0 || index === 1) {
                    return true;
                } else {
                    return this.showAllJobs;
                }
            },

            isHiddenJob(jobUuid) {
                const job = this.$_meJobEvents.find(o => o.job_uuid === jobUuid);
                return job && job.is_hidden;
            },
        },
    };
