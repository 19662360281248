
    import { mapState, mapGetters, mapMutations } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faSearch as farSearch,
    } from '@fortawesome/pro-regular-svg-icons/faSearch';

    import {
        faTrashAlt as farTrashAlt,
    } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';

    import {
        faHistory as farHistory,
    } from '@fortawesome/pro-regular-svg-icons/faHistory';

    export default {
        components: {
            JobViewModal: () => process.client ? import(/* webpackChunkName: "JobViewModal" */'@/components/JobViewModal/JobViewModal') : {},
            FontAwesomeIcon,
        },

        data() {
            return {
                icons: {
                    farSearch,
                    farTrashAlt,
                    farHistory,
                },
            };
        },

        computed: {
            ...mapState({
                $_jobModal: state => state.jobModal,
            }),

            ...mapGetters('jobModal', {
                $_isJobModalOpen: 'isJobModalOpen',
            }),
        },

        watch: {
            $_isJobModalOpen(value) {
                if (!value) {
                    this.$nextTick(() => {
                        window.scrollTo(0, this.$_jobModal.currentVerticalScroll);
                    });
                }
            },
        },

        methods: {
            ...mapMutations('jobModal', {
                $_STORE_JOB_UUID: 'STORE_JOB_UUID',
                $_STORE_VERTICAL_SCROLL_POSITION: 'STORE_VERTICAL_SCROLL_POSITION',
                $_STORE_SECTION_TITLE: 'STORE_SECTION_TITLE',
            }),

            ...mapMutations('jobTeaser', {
                $_STORE_JOB_TEASER: 'STORE_JOB_TEASER',
            }),
        },
    };
