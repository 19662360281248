
    import { mapState, mapActions, mapMutations } from 'vuex';
    import { Bus } from '@/utils/bus';
    import datalayer from '@/utils/datalayer';
    import { jobData } from '@/utils/jobData';

    export default {
        name: 'JobSave',

        components: {
        },

        props: {
            job: {
                type: Object,
                required: true,
            },

            iconOnly: {
                type: Boolean,
                default: false,
            },

            variant: {
                type: String,
                default: '',
            },

            buttonClass: {
                type: String,
                default: '',
            },

            buttonSize: {
                type: String,
                default: 'lg',
            },

            isDark: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                currentSaveIcon: '',
                email: '',
                spinner: false,
                showPromptModal: false,
                loginPrompt: {
                    component_name: 'LoginPrompt',
                    title: 'Save job',
                    code: 'SIMPLE_LOGIN',
                    page_referral_element: 'job_Save',
                    site_position: 'login_signup_popup',
                    options: {
                        login_redirect: '_none',
                    },
                },
            };
        },

        computed: {
            ...mapState({
                $_me: state => state.me.user,
                $_meJobEvents: state => state.me.jobEvents,
                $_scoringData: state => state.me.scoringData,
            }),

            classList() {
                return [
                    'job-save',
                    this.getSpinnerClass,
                    this.getStateClass,
                    this.buttonClass,
                    this.iconOnly && !this.variant ? 'is-icon-only px-0 py-0 h-initial' : '',
                    this.iconOnly && this.variant ? 'is-icon-only px-0 py-0 w-12 flex-shrink-0' : '',
                ];
            },

            getVariant() {
                let variant = '';

                if (this.iconOnly && !this.variant) {
                    variant = 'default';
                } else if (this.variant) {
                    variant = this.variant;
                } else {
                    variant = 'theme-outline-light';
                }
                return variant;
            },

            getSpinnerClass() {
                return this.spinner ? 'is-spinning' : '';
            },

            getStateClass() {
                return this.isSavedJob ? 'is-saved' : '';
            },

            isSavedJob() {
                const job = this.$_meJobEvents.find(o => o.job_uuid === this.job.uuid);
                return job && job.is_saved;
            },

            $_jobData() {
                return {
                    c1_datalayer_source: 'c1',
                    seeker_data: this.$_scoringData,
                    ...jobData(this.job),
                };
            },
        },

        watch: {
            isSavedJob() {
                this.defaultSaveIcon();
            },
        },

        created() {
            const saveJobUuId = this.$store.state.me.saveJobAfterLogin;
            if (saveJobUuId && saveJobUuId === this.job.uuid) {
                this.saveJob();
            }
            this.defaultSaveIcon();
        },

        methods: {
            ...mapActions('me', {
                $_storeSavedJob: 'storeSavedJob',
                $_destroySavedJob: 'destroySavedJob',
                $_pushToOnboarding: 'pushToOnboarding',
            }),

            ...mapActions('eventTracking', {
                $_pushEvent: 'pushEvent',
                $_pushSeekerJobEvent: 'pushSeekerJobEvent',
            }),

            ...mapMutations('me', {
                $_DESTROY_SAVED_JOB: 'DESTROY_SAVED_JOB',
                $_PUT_JOB_EVENT: 'PUT_JOB_EVENT',
                $_INCREASE_DISCOVERY_NAV_COUNT: 'INCREASE_DISCOVERY_NAV_COUNT',
                $_DECREASE_DISCOVERY_NAV_COUNT: 'DECREASE_DISCOVERY_NAV_COUNT',
            }),

            onClick() {
                const dataLayer = {
                    event: 'save_job_click',
                    saving: !this.isSavedJob,
                    ...this.$_jobData,
                };

                datalayer(dataLayer);
                this.$_pushEvent(dataLayer);

                this.$_pushSeekerJobEvent(dataLayer);

                if (this.$_me.id) {
                    if (this.isSavedJob) {
                        this.destroyJob();
                    } else {
                        this.saveJob();
                    }
                } else if (this.isSavedJob) {
                    this.$_PUT_JOB_EVENT({ job: this.job.uuid, status: 'is_saved' });
                    Bus.$emit('savedJob::destroy', this.job);
                } else {
                    this.openPromptModal();
                }
            },

            openPromptModal() {
                this.showPromptModal = true;
            },

            closePromptModal() {
                this.showPromptModal = false;
            },

            onPromptSuccess() {
                this.saveJob();
                this.closePromptModal();
            },

            saveJob() {
                this.spinner = true;
                this.$_storeSavedJob({ job_uuid: this.job.uuid }).then((response) => {
                    this.$_PUT_JOB_EVENT({ job: this.job.uuid, status: 'is_saved' });
                    this.$_INCREASE_DISCOVERY_NAV_COUNT('saved_jobs');
                    Bus.$emit('savedJob::create', this.job);
                }).finally(() => {
                    this.spinner = false;
                });
            },

            destroyJob() {
                this.spinner = true;
                this.$_destroySavedJob(this.job.uuid).then(() => {
                    this.$_PUT_JOB_EVENT({ job: this.job.uuid, status: 'is_saved' });
                    this.$_DECREASE_DISCOVERY_NAV_COUNT('saved_jobs');
                    Bus.$emit('savedJob::destroy', this.job);
                }).finally(() => {
                    this.spinner = false;
                });
            },

            defaultSaveIcon() {
                if (this.isSavedJob) {
                    this.currentSaveIcon = require('@/assets/img/icon-star-filled.svg');
                } else {
                    this.currentSaveIcon = this.isDark ? require('@/assets/img/icon-star-default-dark.svg') : require('@/assets/img/icon-star-default.svg');
                }
            },

            onMouseOver() {
                if (this.isSavedJob) {
                    this.currentSaveIcon = require('@/assets/img/icon-star-filled-hover.svg');
                } else {
                    this.currentSaveIcon = require('@/assets/img/icon-star-default-hover.svg');
                }
            },

            onMouseLeave() {
                this.defaultSaveIcon();
            },
        },
    };
