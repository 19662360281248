
    import { mapActions, mapMutations, mapState } from 'vuex';
    // import Cookie from 'js-cookie';

    export default {
        name: 'GetJobMatchesPromptLg',

        data() {
            return {
                showPromptModal: false,
                onBoardingPrompt: {
                    component_name: 'OnBoardingPrompt',
                    layout: 'lg',
                    code: 'ON_BOARDING_PROMPT',
                    page_referral_element: 'get_matches_wide_card',
                },
            };
        },

        computed: {
            ...mapState({
                $_showPrompt: state => state.prompt.showPrompt,
            }),
        },

        methods: {
            ...mapActions('prompt', {
                $_getPrompt: 'getPrompt',
            }),

            ...mapActions('eventTracking', {
                $_pushEvent: 'pushEvent',
                $_saveDomainSession: 'saveDomainSession',
            }),

            ...mapMutations('prompt', {
                $_DISABLE_PROMPT: 'DISABLE_PROMPT',
            }),

            visibilityChanged(isVisible) {
                if (isVisible) {
                    const data = {
                        event: 'prompt_impression',
                        code: 'get_matches_wide_card',
                    };
                    this.$_pushEvent(data);
                }
            },

            openPromptModal() {
                const data = {
                    event: 'prompt_click',
                    action: 'LINK',
                    code: 'get_matches_wide_card',
                };

                this.$_pushEvent(data);
                this.showPromptModal = true;
            },

            closePromptModal() {
                this.showPromptModal = false;
            },
        },
    };
