
    export default {
        name: 'JobCardLocations',

        props: {
            job: {
                type: Object,
                required: true,
            },
        },

        computed: {
            hasSuburbTextInLevel3() {
                return this.job.location_level_3_label?.includes(this.job.location_level_1_label);
            },
            hasSuburbTextInLevel2() {
                return this.job.location_level_2_label?.includes(this.job.location_level_1_label);
            },
            level2AreaLabel() {
                if (!this.job.location_level_2_label) {
                    return '';
                }
                if (this.job.location_level_2_label.endsWith(` ${this.job.location_state_code}`)) {
                    return `${this.job.location_level_2_label}`;
                } else {
                    return `${this.job.location_level_2_label} ${this.job.location_state_code}`;
                }
            },
        },

        methods: {
            getSuburbLink() {
                const routePath = this.$route.path;
                if (routePath.indexOf('-jobs') > 0 && routePath.indexOf('/in-') <= 0) {
                    return `${routePath.split('-jobs')[0]}-jobs/in-${this.job.location_slug}`;
                } else {
                    return `/jobs/in-${this.job.location_slug}`;
                }
            },

            getLocationLink() {
                const routePath = this.$route.path;
                if (routePath.indexOf('-jobs') > 0 && routePath.indexOf('/in-') <= 0) {
                    return `${routePath.split('-jobs')[0]}-jobs/in-${this.job.location_level_3_slug || this.job.location_slug}`;
                } else {
                    return `/jobs/in-${this.job.location_level_3_slug || this.job.location_slug}`;
                }
            },

            getRegionalLink() {
                const routePath = this.$route.path;
                if (routePath.indexOf('-jobs') > 0 && routePath.indexOf('/in-') <= 0) {
                    return `${routePath.split('-jobs')[0]}-jobs/in-${this.job.location_level_3_slug || this.job.location_level_2_slug}`;
                } else {
                    return `/jobs/in-${this.job.location_level_3_slug || this.job.location_level_2_slug}`;
                }
            },

            onLocationClicked() {
                return false;
            },

            getDefaultLocationLabel() {
                return this.job.location_type === 'STATE' ? this.job.location_level_4_label : this.job.location_label;
            },
        },
    };
