
    import { mapState, mapGetters } from 'vuex';
    import {
        faTachometer as farTachometer,
    } from '@fortawesome/pro-regular-svg-icons/faTachometer';

    import {
        faTachometerAltFastest as farTachometerAltFastest,
    } from '@fortawesome/pro-regular-svg-icons/faTachometerAltFastest';

    import {
        faTachometerAltSlow as farTachometerAltSlow,
    } from '@fortawesome/pro-regular-svg-icons/faTachometerAltSlow';

    import C1CircularProgressBar from '@/C1Components/C1CircularProgressBar.vue';
    import ScoreUnlockIcon from '@/components/Scoring/ScoreUnlockIcon';
    import scoreHelper from '@/utils/scoreHelper';

    export default {
        name: 'ScoreButton',

        components: {
            JobScoreModal: () => process.client ? import('@/components/Job/JobScoreModal') : {},
            ScoreUnlockIcon,
            C1CircularProgressBar,
        },

        props: {
            job: {
                type: Object,
                required: true,
            },

            title: {
                type: String,
                default: 'match',
            },

            scoreSuffix: {
                type: String,
                default: '%',
            },

            noModal: {
                type: Boolean,
                default: false,
            },

            size: {
                type: String,
                default: 'sm',
            },
        },

        data() {
            return {
                isJobScoreModalOpen: false,
                icons: {
                    farTachometer,
                    farTachometerAltFastest,
                    farTachometerAltSlow,
                },
            };
        },

        computed: {
            ...mapState({
                $_me: state => state.me.user,
                $_scoringData: state => state.me.scoringData,
                $_meDesiredOccupations: state => state.me.desiredOccupations,
                $_scoringMatrixData: state => state.me.scoringMatrixData,
            }),

            ...mapGetters('me', {
                $_isOnboarded: 'isOnboarded',
            }),

            classList() {
                return [
                    'score-button',
                    this.getScoreClass,
                    this.getModalClass,
                    this.getSizeClass,
                ];
            },

            getSizeClass() {
                const sizes = {
                    sm: 'btn-sm',
                    md: 'btn-md',
                    lg: 'btn-lg',
                };

                return sizes[this.size];
            },

            getModalClass() {
                return this.noModal ? 'no-modal' : '';
            },

            getScoreClass() {
                let className;

                if (this.scoring.percentage <= 33) {
                    className = 'is-low';
                } else if (this.scoring.percentage > 66) {
                    className = 'is-high';
                } else {
                    className = 'is-medium';
                }

                return className;
            },

            confidenceClass() {
                return [
                    'score-button-confidence',
                    this.getConfidenceScoreClass,
                ];
            },

            tachometerIcon() {
                return this.scoring.confidence > 0 ? this.icons.farTachometerAltFastest : this.icons.farTachometerAltSlow;
            },

            getConfidenceScoreClass() {
                let className;

                if (this.scoring.confidence > 0) {
                    className = 'is-high';
                } else {
                    className = 'is-low';
                }

                return className;
            },

            scoring() {
                return scoreHelper.calculateScoreFrontEnd(this.job, this.$_scoringData, this.$_scoringMatrixData);
            },
        },

        methods: {
            onClick() {
                if (this.$_isOnboarded) {
                    if (!this.noModal) {
                        this.openJobScoreModal();
                    }
                } else {
                    this.$emit('onClick');
                }
            },

            openJobScoreModal() {
                this.isJobScoreModalOpen = true;
            },

            closeJobScoreModal() {
                this.isJobScoreModalOpen = false;
            },
        },
    };
