
    export default {
        name: 'JobCardAttributes',

        props: {
            attributes: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {};
        },

        computed: {
            getAttributes() {
                return this.attributes.filter((attribute, index) => {
                    return attribute && index < 2;
                });
            },
        },

        methods: {},
    };
