
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';

    dayjs.extend(utc);

    export default {
        name: 'JobDate',

        props: {
            date: {
                type: String,
                default: '',
            },
            expiringDate: {
                type: String,
                default: '',
            },
            isExpired: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {};
        },
        methods: {
            classList() {
                const isExpiring = this.expiringDate && (dayjs.utc(this.expiringDate).local().diff(dayjs().local(), 'days') < 5);
                const isExpired = this.isExpired || (this.expiringDate && (dayjs.utc(this.expiringDate).local().diff(dayjs().local(), 'days') <= -1));
                const isNew = (dayjs.utc(this.date).local().diff(dayjs().local(), 'days') > -1);

                return [
                    'job-date',
                    isExpired ? 'is-expired' : '',
                    isExpiring ? 'is-expiring' : '',
                    isNew ? 'is-new' : '',
                ];
            },
            text(date, isExpired, expiringDate) {
                if (!date) {
                    return '';
                }

                const expiredDays = dayjs().local().diff(dayjs.utc(expiringDate).local(), 'days');
                const expiredDaysUTC = dayjs.utc(expiringDate).local().diff(dayjs().local(), 'days');
                const isExpiring = expiringDate && (expiredDaysUTC < 5);
                const isExpireddate = isExpired || (expiringDate && (expiredDaysUTC <= -1));

                if (isExpiring && !isExpireddate) {
                    const expiredDaysText = Math.abs(expiredDays) + 'd';
                    if (expiredDaysText === '0d') {
                        return 'Expiring today';
                    }
                    return `Expiring in ${expiredDaysText}`;
                } else if (isExpired || isExpireddate) {
                    if (expiredDays === 0) {
                        return 'Expired today';
                    } else {
                        const expiredDaysText = expiredDays < 60 ? (expiredDays || 1) : '+60';
                        return `Expired ${expiredDaysText}d ago`;
                    }
                }

                const postDate = new Date(date.replace(/-/g, '/') + ' UTC');
                const dateA = dayjs.utc(postDate).local();
                const dateB = dayjs().utc().local();
                const daysAgo = dateA.startOf('day').diff(dateB.startOf('day'), 'days', true);

                if (Math.abs(daysAgo) > 60) {
                    return '+60d ago';
                }

                return Math.abs(daysAgo) === 0 ? 'Posted today' : `Posted ${Math.abs(daysAgo)}d ago`;
            },
        },
    };
