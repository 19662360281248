
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faChevronRight as fasChevronRight,
    } from '@fortawesome/pro-solid-svg-icons/faChevronRight';

    export default {
        name: 'JobCardCategoryInfo',

        components: {
            FontAwesomeIcon,
        },

        props: {
            job: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                icons: {
                    fasChevronRight,
                },
            };
        },

        methods: {
            getCategoryLink() {
                const routePath = this.$route.path;
                let categoryLink = `/jobs-in-${this.job.category_slug}`;
                if (this.job.location_level_3_slug && !routePath.includes('/in-')) {
                    const locationLink = `in-${this.job.location_level_3_slug}`;
                    categoryLink = `${categoryLink}/${locationLink}`;
                }
                return categoryLink;
            },

            getOccupationLink() {
                const routePath = this.$route.path;
                let occupationLink = '/jobs';
                if (this.job.location_level_3_slug && !routePath.includes('/in-')) {
                    const locationLink = `in-${this.job.location_level_3_slug}`;
                    occupationLink = `${occupationLink}/${locationLink}`;
                }
                occupationLink = `${occupationLink}/occ_${this.job.occupation_slug}`;
                return occupationLink;
            },
        },
    };
