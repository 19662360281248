
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faChevronRight as fasChevronRight,
    } from '@fortawesome/pro-solid-svg-icons/faChevronRight';

    export default {
        name: 'JobCardBrand',

        components: {
            FontAwesomeIcon,
        },

        props: {
            eeo: {
                type: Array,
                default: () => [],
            },

            vacancies: {
                type: Number,
                default: 0,
            },

            bgColor: {
                type: String,
                default: '',
            },

            primaryBrandName: {
                type: String,
                default: '',
            },

            primaryBrandLink: {
                type: String,
                default: '',
            },

            brandName: {
                type: String,
                default: '',
            },

            brandLink: {
                type: String,
                default: '',
            },

            displayPrimaryBrandCrumb: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                icons: {
                    fasChevronRight,
                },
            };
        },

        computed: {
            disableBrandLink() {
                return this.$route.path.includes('br_');
            },
        },
    };
